import React from "react"
import Img from "gatsby-image"
import Layout from "../components/layout.js"
import Leftcol from "../components/leftcol"
import Maincol from "../components/maincol"
import Caption from "../components/caption"
import SEO from "../components/seo"

export default ({ data }) => (
  <Layout>
    <Leftcol>
      <div className="mb-4 sm:mb-8">
      <Img fluid={data.hawaii.childImageSharp.fluid} />
      <Caption text="Peter Filanc in Honolulu, Hawaii, one of his favorite places to vacation, 2004" />
      </div>
      <Img fluid={data.family.childImageSharp.fluid} />
      <Caption text="Peter Filanc with Erica Tanamachi, Yvette Filanc and his granddaughter Anushka Rose, January, 2007" />
    </Leftcol>
    <Maincol>
    <SEO title="History" />
    <h1>History</h1>

    <p>
      The Peter John Filanc foundation was established by Peter’s two daughters,
      Erica Tanamachi and Yvette Filanc, to pursue Peter’s legacy of supporting
      students and educational foundations. Peter Filanc, CEO of J.R. Filanc
      Construction, was a dedicated philanthropist and a nationally recognized
      leader in the water and wastewater industries. Filanc graduated from the
      University of California, Berkeley, where he earned a Master of Science
      degree in civil engineering, with an emphasis in sanitary and hydraulic
      engineering. He started his career at Engineering Sciences as a project
      manager. At Engineering Sciences, Peter participated in developing the
      firm’s integration of microcomputers into operating wastewater treatment
      plants.
    </p>
    <h3>Leadership in Construction and Education</h3>
    <p>
      In 1979, Filanc began working at his father’s company, what is now known
      as JR Filanc Construction. He started in the field as a superintendent and
      rose up the ranks, learning every job firsthand until eventually taking
      over the reins as president in 1990. Filanc was an early advocate of
      design-build, ultimately becoming one of the nation’s foremost experts.
      When California officials were contemplating legislation authorizing
      public owners to use the design-build procurement method, Filanc was
      called to testify before the Senate.
    </p>
    <p>
      Filanc was also a devoted supporter of education and created a brand new
      major at SDSU. He designed, fundraised and established the first ever
      bachelor program in construction engineering and management within San
      Diego State University’s College of Engineering. He raised five million
      dollars within only a few years to hire the staff and implement the
      program.
    </p>
    <p>
      He also helped pave the way for many other educational and mentoring
      programs, including AGC’s Construction Project Manager Course, which
      graduates hundreds of project managers every year. He was the first
      construction executive to volunteer in the City of San Diego/AGC
      Mentor-Protégé Program. He mentored a small general engineering company,
      helping them grow into a mid-size company.
    </p>
    <h3>Peter's Legacy</h3>
    <p>
      In 2004, Peter Filanc received the AGC SIR Award, San Diego AGC’s highest
      honor. At the time of the award, he was only the 18th
      construction-industry leader to be so honored in the association’s 76-year
      history. Throughout his life, Peter donated thousands of dollars to
      support education, the arts, medical research, construction and
      engineering organizations. In addition, Peter donated thousands of hours
      teaching scholars and professionals all over the country about the present
      and future of the construction industry. He was a true visionary who could
      inspire a room full of people within minutes of speaking with them.
      Peter’s legacy lives on through all of the lives he touched and the lives
      this foundation continues to support.
    </p>



    <p>
      For more information, please contact: Erica Tanamachi{" "}
      <a href="mailto:erica@ericajanefilms.com">erica@ericajanefilms.com</a>
    </p>
    </Maincol>
  </Layout>
)

export const query = graphql`
  query {
    hawaii: file(relativePath: { eq: "images/peter-hawaii.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    family: file(relativePath: { eq: "images/erica-dad-yvette.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
